import React from 'react';
import {graphql, Link, PageProps} from 'gatsby';
import SEO from '../components/SEO';

import { H1 ,P} from '../styles/Fonts';

import {Logo } from '../components/Layout';
export interface PageSuccessProps {
    markdownRemark :{
        frontmatter : {
        description : string;
        title : string;
        button : {
            to : string;
            description : string;
        }
        }
    }
}

interface SuccessTemplateProps{
    title : string,
    description : string,
    button : {
        to : string;
        description : string;
    }
    location:{pathname : string;};
    preview? : boolean;
}

export  function PageSuccessTemplate({ title ,description,button,location,preview  } : SuccessTemplateProps){

return (<>
       {!preview?(<SEO title={title} description={description} pathname={location.pathname} />) :''}


	<H1 size={"S3"} color={"primary"}>{title}</H1>
     <P  size={"S3"} color={"textTitle"} > {description}</P>
     <Link to={button.to}>{button.description}</Link>
    </>);}

const  PageSuccess = ({data, location} :PageProps<PageSuccessProps>)=>{
    const content = data.markdownRemark.frontmatter;

 return(
     <PageSuccessTemplate
     title={content.title}
     description={content.description}
     location ={location}
     button ={content.button}

     />
 );
 }
export default PageSuccess;

export const query = graphql`
    query SuccessPageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "success-page"}}) {
        frontmatter {
        description
        title
        button {
            to
            description
        }
        }
    }
    }
`;